import { Box, Button, DialogTitle, Drawer, FormControl, FormHelperText, FormLabel, Grid, InputAdornment, InputBase, MenuItem, Select, Slide, Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import React from "react";


import axios from "axios";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useSelector } from "react-redux";
import downarrow_back from "../../../assets/back.png";
import calender_black from "../../../assets/calender_black.svg";
import customer_name from "../../../assets/customer_name.svg";
import timerclock_black from "../../../assets/timerclock_black.svg";
import { useLocalStorage } from "../../../hooks";
import { userState } from "../../../redux/reducers/userReducer";
import "../../../styles/Calendar.css";
import { getUrlForBookings } from "../../../utils/confighelpers";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

const formLableStyle = {
  my: 1,
  color: "rgba(0, 0, 0, 0.6);"
};

const formInputStyle = {
  p: 2,
  // border: 1,
  borderRadius: 3,
  // borderColor: formErrors["className"] ? "#d32f2f" : "#CCCCCC",
  backgroundColor: "rgba(0, 0, 0, 0.04)",
  minWidth: 455,
  maxWidth: 455,
  "&:hover, &.Mui-focused": {
    border: 1,
    borderColor: "#7E00DD"
  },
  '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0,
    borderColor: 'rgba(0, 0, 0, 0)',
  },
};

const formInputClasses = {
  input: {
    "&::placeholder": {
      color: "#808080",
      fontWeight: 300,
    },
  },
};

const formSelectClasses = {
  sx: {
    "& .MuiMenu-paper": {
      border: 1,
      borderColor: "#424242",
      borderRadius: 3,
      maxHeight: 250,
      mt: 1,
    },
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
  },
};

const taboptions = [
  {
    value: "freeTime",
    text: "Free Time",
  },
  {
    value: "freeDays",
    text: "Free Days",
  },
];

const slotsDisplay = {
  "15": "15 minutes",
  "30": "30 minutes",
  "45": "45 minutes",
  "60": "60 minutes",
  "75": "1 hours 15 minutes",
  "90": "1 hours 30 minutes",
  "105": "1 hours 45 minutes",
  "120": "2 hours",
  "135": "2 hours 15 minutes",
  "150": "2 hours 30 minutes",
  "165": "2 hours 45 minutes",
  "180": "3 hours",
  "195": "3 hours 15 minutes",
  "210": "3 hours 30 minutes",
  "225": "3 hours 45 minutes",
  "240": "4 hours",
  "255": "4 hours 15 minutes",
  "270": "4 hours 30 minutes",
  "285": "4 hours 45 minutes",
  "300": "5 hours",
  "315": "5 hours 15 minutes",
  "330": "5 hours 30 minutes",
  "345": "5 hours 45 minutes",
  "360": "6 hours",
  "375": "6 hours 15 minutes",
  "390": "6 hours 30 minutes",
  "405": "6 hours 45 minutes",
  "420": "7 hours",
  "435": "7 hours 15 minutes",
  "450": "7 hours 30 minutes",
  "465": "7 hours 45 minutes",
  "480": "8 hours",
}

export default function AddNewAppointmentModal({
  isEdit, loading, open, setAddNewAppointmentDialog, text, data, onClose, onSubmit
}) {
  const [userToken] = useLocalStorage("userToken");
  const userReduxState = useSelector(userState);
  const [formValues, setFormValues] = React.useState({});
  const [formErrors, setFormErrors] = React.useState({});
  const [openSlotDate, setOpenSlotDate] = React.useState(false);
  const [durationList, setDurationList] = React.useState([]);
  const [availableSlots, setAvailableSlots] = React.useState([]);
  const [actionTab, setActionTab] = React.useState(1);
  const [resourceList, setResourceList] = React.useState(1);
  React.useEffect(() => {
    // console.log(data);
    setFormValues(data)
  }, [data]);
  React.useEffect(() => {
    resetform()
  }, [open]);
  React.useEffect(() => {
    // console.log("formValues", formValues);
    (async () => {
      await reloadAvailableSlots();
    })()
  }, [formValues?.serviceId,
  formValues?.duration,
  formValues?.resourceId,
  formValues?.slot_date]);

  React.useEffect(() => {
    (async () => {
      // resetform()
      await reloadAvailableSlots();
    })()
  }, []);

  React.useEffect(() => {
    (async () => {
      if (formValues.serviceId == undefined && formValues?.serviceList && formValues?.serviceList.length) {
        let slots = formValues?.serviceList[0].data.slots;
        setDurationList(slots)
        setFormValues((prevValues) => ({
          ...prevValues,
          serviceId: formValues?.serviceList && formValues?.serviceList.length > 0 ? formValues?.serviceList[0].id : "",
          serviceDetail: formValues?.serviceList && formValues?.serviceList.length > 0 ? formValues?.serviceList[0].data : {},
          venue_id: formValues?.serviceList && formValues?.serviceList.length > 0 ? formValues?.serviceList[0].data.venue_id : "",
          resourceId: formValues?.userDetails?.myResource && formValues?.userDetails?.myResource.length > 0 ? formValues?.userDetails?.myResource[0]._id : "",
          resourceDetail: formValues?.userDetails?.myResource && formValues?.userDetails?.myResource.length > 0 ? formValues?.userDetails?.myResource[0] : {},
          duration: slots[0].duration,
          priceType: slots[0].priceType,
          price: slots[0].price,
        }));
        // setTimeout(async () => {
        //   await reloadAvailableSlots();
        // }, 1000);
      }
    })()
  }, [formValues, formValues?.serviceList]);
  // }, []);

  const getAvailableSlots = async () => {
    let mgServices = [];
    const manageAvailableSlotsConfig = {
      method: "POST",
      url: `${getUrlForBookings()}getAvailableSlots`,
      headers: {
        "x-access-token": userToken,
      },
      data: {
        "slot_date": moment(formValues.slot_date).format("YYYY-MM-DD"),
        "duration": formValues.duration,
        "priceType": formValues.priceType,
        "service_id": formValues.serviceId,
        "venue_id": formValues.venue_id,
        "business_id": userReduxState.userData?._id,
        "resourceId": formValues.resourceId,
      },
    };
    const manageAvailableSlotsRequest = await axios(manageAvailableSlotsConfig);
    if (manageAvailableSlotsRequest.status === 200) {
      if (manageAvailableSlotsRequest.data.data?.resource_slot_detail?.data?.available_slots) {
        setAvailableSlots(manageAvailableSlotsRequest.data.data.resource_slot_detail.data.available_slots.filter((d) => {
          let istime = moment(moment(formValues.slot_date).format("YYYY-MM-DD") + " " + d.startTime, "YYYY-MM-DD h:mm A").isAfter(moment())
          return istime
        }));
      }
    }
  };
  const reloadAvailableSlots = async (dates) => {
    if (formValues?.serviceId !== undefined && formValues?.serviceId !== ""
      && formValues?.duration !== undefined && formValues?.duration !== ""
      && formValues?.priceType !== undefined && formValues?.priceType !== ""
      && formValues?.venue_id !== undefined && formValues?.venue_id !== ""
      && formValues?.resourceId !== undefined && formValues?.resourceId !== ""
      && formValues?.slot_date !== undefined && formValues?.slot_date !== "") {

      await getAvailableSlots();
    } else {
      setAvailableSlots([])
    }
  };
  const resetform =  () => {    
    setActionTab(1)
    setAvailableSlots([])
    setFormErrors({})
  };

  return (
    <Drawer
      ModalProps={{
        onBackdropClick: () => { },
        keepMounted: true,
      }}
      anchor="right"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      PaperProps={{
        sx: {
          // borderRadius: 5,
        },
      }}
    >
      <DialogTitle>{isEdit ? "Edit" : "Add New"} Appointment</DialogTitle>
      <Box className={"flex flex-col w-full gap-3 py-6 px-8"}>
        <Grid container xs={12} direction={"column"}>
          {actionTab == 1 ? (<>
            <FormControl error={formErrors["serviceId"] ? true : false}>
              <FormLabel sx={formLableStyle}>Service</FormLabel>
              <Select
                disableunderline="true"
                startAdornment={
                  <InputAdornment position={"start"}>
                    <img className={"w-6"} src={customer_name} alt={""} />
                  </InputAdornment>
                }
                renderValue={(value) => (
                  <div className={"flex flex-row items-center gap-3"} style={{ textTransform: 'capitalize' }}>
                    {value?.id ? value?.label : (
                      <span className={"font-light text-plain-gray"}>
                        Select Service
                      </span>
                    )}
                  </div>
                )}
                name={"serviceId"}
                value={formValues.serviceId != "" && formValues?.serviceList && formValues?.serviceList.length && formValues?.serviceList.find((d) => d.id == formValues.serviceId) || "Select Service"}
                onChange={(e) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    // resourceData: e.target.value,
                    serviceId: e.target.value.id,
                    serviceDetail: e.target.value.data,
                    venue_id: e.target.value.data.venue_id,
                    duration: 0,
                    priceType: "",
                    price: 0,
                  }))

                  setDurationList(e.target.value.data.slots)
                }}
                MenuProps={formSelectClasses}
                sx={{
                  ...formInputStyle,
                  py: 0.5,
                }}
              >
                {formValues?.serviceList && formValues?.serviceList.map((d, i) => {
                  return (<MenuItem value={d} key={i}>{d.label}</MenuItem>)
                })}
              </Select>
              {formErrors["serviceId"] && (
                <FormHelperText>{formErrors["serviceId"]}</FormHelperText>
              )}
            </FormControl>
            <FormControl error={formErrors["resourceId"] ? true : false}>
              <FormLabel sx={formLableStyle}>Resource</FormLabel>
              <Select
                disableunderline="true"
                startAdornment={
                  <InputAdornment position={"start"}>
                    <img className={"w-6"} src={customer_name} alt={""} />
                  </InputAdornment>
                }
                renderValue={(value) => (
                  <div className={"flex flex-row items-center gap-3"} style={{ textTransform: 'capitalize' }}>
                    {value?._id ? value?.fullName : (
                      <span className={"font-light text-plain-gray"}>
                        Select Resource
                      </span>
                    )}
                  </div>
                )}
                name={"resourceId"}
                value={formValues?.userDetails?.myResource.find((d) => d._id == formValues.resourceId) || "Select Resource"}
                onChange={(e) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    // resourceData: e.target.value,
                    resourceId: e.target.value._id,
                    resourceDetail: e.target.value,
                  }))
                }}
                MenuProps={formSelectClasses}
                sx={{
                  ...formInputStyle,
                  py: 0.5,
                }}
              >
                {formValues?.userDetails?.myResource.map((d, i) => {
                  return (<MenuItem value={d} key={i}>{d.fullName}</MenuItem>)
                })}
              </Select>
              {formErrors["resourceId"] && (
                <FormHelperText>{formErrors["resourceId"]}</FormHelperText>
              )}
            </FormControl>
            <FormControl error={formErrors["priceType"] ? true : false}>
              <FormLabel sx={formLableStyle}>Choose Duration</FormLabel>
              <Select
                disableunderline="true"
                startAdornment={
                  <InputAdornment position={"start"}>
                    <img className={"w-6"} src={timerclock_black} alt={""} />
                  </InputAdornment>
                }
                renderValue={(value) => (
                  <div className={"flex flex-row items-center gap-3"} style={{ textTransform: 'capitalize' }}>
                    {value?.priceType ? `${slotsDisplay[value.duration]} - €${value.price}` : (
                      <span className={"font-light text-plain-gray"}>
                        Select Duration
                      </span>
                    )}
                  </div>
                )}
                name={"priceType"}
                value={durationList.find((d) => d.priceType == formValues.priceType) || "Select Duration"}
                onChange={(e) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    // resourceData: e.target.value,
                    duration: e.target.value.duration,
                    priceType: e.target.value.priceType,
                    price: e.target.value.price,
                  }))
                }}
                MenuProps={formSelectClasses}
                sx={{
                  ...formInputStyle,
                  py: 0.5,
                }}
              >
                {formValues?.serviceId && durationList && durationList.map((d, i) => {
                  return (<MenuItem value={d} key={i}>{`${slotsDisplay[d.duration]} - €${d.price}`}</MenuItem>)
                })}
              </Select>
              {formErrors["priceType"] && (
                <FormHelperText>{formErrors["priceType"]}</FormHelperText>
              )}
            </FormControl>
            {/* <FormControl error={formErrors["title"] ? true : false}>
            <FormLabel sx={formLableStyle}>Title</FormLabel>
            <InputBase
              name={"title"}
              type="text"
              placeholder="Title"
              value={formValues.title}
              inputProps={{ maxLength: 30 }}
              onChange={(e) => {
                setFormValues((prevValues) => ({
                  ...prevValues,
                  title: e.target.value,
                }))
              }}
              classes={formInputClasses}
              sx={formInputStyle}
            />
            {formErrors["title"] && (
              <FormHelperText>{formErrors["title"]}</FormHelperText>
            )}
          </FormControl> */}
            <Grid item >
              <FormControl error={formErrors["slot_date"] ? true : false}>
                <FormLabel sx={formLableStyle}>Choose Date</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    minDate={new Date()}
                    value={formValues.slot_date}
                    // value={moment(formValues.slot_date, 'YYYY-MM-DD').format("MM/DD/YYYY")}
                    onChange={(newValue) => {
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        slot_date: newValue,
                      }))
                    }}
                    open={openSlotDate}
                    onOpen={() => { setOpenSlotDate(true) }}
                    onClose={() => { setOpenSlotDate(false) }}
                    renderInput={(params) => {
                      const { inputRef, inputProps, InputProps } = params;
                      const { placeholder, classes, ...restinputProps } =
                        inputProps;
                      return (
                        <InputBase
                          ref={inputRef}
                          {...restinputProps}
                          placeholder={"Select Start Date"}
                          classes={formInputClasses}
                          sx={{
                            ...formInputStyle,
                            borderColor: formErrors["slot_date"]
                              ? "#d32f2f"
                              : "#EBEBEB",
                          }}
                          onClick={() => { setOpenSlotDate(!openSlotDate) }}
                          startAdornment={
                            <InputAdornment position={"start"}>
                              <img className={"w-5"} src={calender_black} alt={""} />
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment position={"end"} className={"cursor-pointer"} >
                              <img className={"w-3"} src={downarrow_back} alt={""} />
                            </InputAdornment>
                          }
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
                {formErrors["slot_date"] && (
                  <FormHelperText>{formErrors["slot_date"]}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Box className="mt-3">
              <FormLabel sx={formLableStyle}>Choose Time</FormLabel>
              {availableSlots.length > 0 ? (<div className={`grid grid-cols-4 gap-2 mt-3`}>
                {availableSlots.map((d) => (<Button
                  variant={"contained"}
                  // sx={{ textTransform: "none", borderRadius: 2.5 }}
                  sx={{
                    color: d.startTime == formValues.slot_start_time ? "common.white" : "secondary.mainLight",
                    backgroundColor: d.startTime == formValues.slot_start_time ? "secondary.mainLight" : "common.white",
                    borderColor: "secondary.mainLight",
                    border: 2,
                    boxShadow: "none",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "secondary.mainLight",
                      color: "common.white",
                      boxShadow: "none",
                    },
                    fontWeight: 600,
                    borderRadius: 5,
                    px: 2,
                    py: 0.5,
                    // width: 220,
                    // height: 52,
                    fontSize: 16,
                  }}
                  onClick={() => {
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      // resourceData: e.target.value,
                      slot_start_time: d.startTime,
                      slot_end_time: d.endTime,
                    }))
                  }}
                >
                  {d.startTime}
                </Button>))}
              </div>) : (
                <Box className="text-center">No slots available, please select a different date</Box>
              )}
              {formErrors["slot_time"] && (
                <FormHelperText className="!text-red-600">{formErrors["slot_time"]}</FormHelperText>
              )}
            </Box>
          </>) : (
            <>

              <FormControl error={formErrors["fullName"] ? true : false}>
                <FormLabel sx={formLableStyle}>Full Name</FormLabel>
                <InputBase
                  name={"fullName"}
                  type="text"
                  placeholder="Full Name"
                  value={formValues.fullName}
                  inputProps={{ maxLength: 30 }}
                  onChange={(e) => {
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      fullName: e.target.value,
                    }))
                  }}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                {formErrors["fullName"] && (
                  <FormHelperText>{formErrors["fullName"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formErrors["username"] ? true : false}>
                <FormLabel sx={formLableStyle}>Phone Number</FormLabel>
                <PhoneInput
                  containerClass={{
                    "&.react-tel-input .special-label": {
                      display: "hide",
                    }
                  }}
                  inputProps={{
                    style: {
                      borderColor: "#0000000a",
                      borderRadius: "12px",
                      backgroundColor: "#0000000a",
                      minWidth: 455,
                      maxWidth: 455,
                      maxLength: 30
                    }
                  }}
                  specialLabel=""
                  country={formValues.countryName || 'nl'}
                  value={formValues.formattedPhoneNumber}
                  onChange={(phone, country, e) => {
                    const numberWithoutDialCode = phone.split("");
                    for (let i = 0; i < country.dialCode.length; i++) {
                      numberWithoutDialCode.shift();
                    }
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      countryName: country.countryCode,
                      countryCode: country.dialCode,
                      phone: numberWithoutDialCode.join(""),
                      formattedPhoneNumber: e.target.value,
                      username: e.target.value.replace(/ /g, "").replace(/-/g, ""),
                    }))
                  }}
                />
                {formErrors["username"] && (
                  <FormHelperText>{formErrors["username"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formErrors["email"] ? true : false}>
                <FormLabel sx={formLableStyle}>Email</FormLabel>
                <InputBase
                  name={"email"}
                  type="text"
                  placeholder="Email"
                  value={formValues.email}
                  inputProps={{ maxLength: 30 }}
                  onChange={(e) => {
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      email: e.target.value,
                    }))
                  }}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                {formErrors["email"] && (
                  <FormHelperText>{formErrors["email"]}</FormHelperText>
                )}
              </FormControl>
            </>
          )}
          <FormHelperText sx={{ color: "#d32f2f" }}>{text}</FormHelperText>
        </Grid>

      </Box>
      <Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ py: 3 }}>
        <Button
          variant={"contained"}
          // sx={{ textTransform: "none", borderRadius: 2.5 }}
          sx={{
            color: "secondary.mainLight",
            backgroundColor: "common.white",
            borderColor: "secondary.mainLight",
            border: 2,
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "secondary.mainLight",
              color: "common.white",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 220,
            height: 52,
            fontSize: 16,
          }}
          onClick={() => {
            if (actionTab > 1) {
              setActionTab(actionTab - 1);
            } else {
              onClose();
            }
          }}
        >
          {actionTab > 1 ? "Back" : "Cancel"}
        </Button>
        {actionTab == 1 ? (<Button
          variant={"contained"}
          color={"secondary"}
          disabled={loading}
          // sx={{ textTransform: "none", borderRadius: 2.5 }}
          sx={{
            color: "common.white",
            backgroundColor: "primary.main",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "primary.main",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 220,
            height: 52,
            fontSize: 16,
          }}
          onClick={() => {
            const errors = {};
            if (!formValues.serviceId) {
              errors.serviceId = "Required!";
            }
            if (!formValues.resourceId) {
              errors.resourceId = "Required!";
            }
            if (!formValues.duration) {
              errors.priceType = "Required!";
            }
            if (!formValues.priceType) {
              errors.priceType = "Required!";
            }
            if (!formValues.slot_date) {
              errors.slot_date = "Required!";
            }
            if (!formValues.slot_start_time) {
              errors.slot_time = "Required!";
            }
            if (!formValues.slot_end_time) {
              errors.slot_time = "Required!";
            }
            if (moment(formValues.slot_end_time, "h:mm A").isBefore(moment(formValues.slot_start_time, "h:mm A"), 'minute')) {
              errors.slot_time = "Past slot time is not allowed!";
            }
            console.log("formValues", formValues);
            console.log("errors", errors);
            setFormErrors(errors)
            if (Object.keys(errors).length === 0) {
              // onSubmit({
              //   ...formValues,
              // });
              setAddNewAppointmentDialog((prevValues) => ({
                ...prevValues,
                text: "",
              }))
              setActionTab(2);
            }
          }}
        >
          Next
        </Button>) : (<Button
          variant={"contained"}
          color={"secondary"}
          disabled={loading}
          // sx={{ textTransform: "none", borderRadius: 2.5 }}
          sx={{
            color: "common.white",
            backgroundColor: "primary.main",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "primary.main",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 220,
            height: 52,
            fontSize: 16,
          }}
          onClick={() => {
            const errors = {};
            if (!formValues.fullName) {
              errors.fullName = "Required!";
            }
            if (!formValues.username) {
              errors.username = "Required!";
            }
            if (!formValues.email) {
              errors.email = "Required!";
            }
            if (formValues.email && formValues.email != "") {
              const pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/);
              if (pattern.test(formValues.email) == false) {
                errors.email = "Please enter correct email address";
              }
            }
            if (!formValues.serviceId) {
              errors.serviceId = "Required!";
              setActionTab(1)
            }
            if (!formValues.resourceId) {
              errors.resourceId = "Required!";
              setActionTab(1)
            }
            if (!formValues.duration) {
              errors.duration = "Required!";
              setActionTab(1)
            }
            if (!formValues.slot_date) {
              errors.slot_date = "Required!";
              setActionTab(1)
            }
            if (!formValues.slot_start_time) {
              errors.slot_time = "Required!";
              setActionTab(1)
            }
            if (!formValues.slot_end_time) {
              errors.slot_time = "Required!";
              setActionTab(1)
            }
            if (moment(formValues.slot_end_time, "h:mm A").isBefore(moment(formValues.slot_start_time, "h:mm A"), 'minute')) {
              errors.slot_time = "Past slot time is not allowed!";
              setActionTab(1)
            }
            console.log("formValues", formValues);
            console.log("errors", errors);
            setFormErrors(errors)
            if (Object.keys(errors).length === 0) {
              setActionTab(1);
              onSubmit({
                ...formValues,
              });
            }
          }}
        >
          Submit
        </Button>)}
      </Stack>
    </Drawer>
  );
}
