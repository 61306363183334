import { Combobox } from "@headlessui/react";
import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, InputAdornment, InputBase, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, styled, tableCellClasses, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import cogoToast from "cogo-toast";
import moment from "moment";
import React, { useState } from "react";
import useGoogleAutocomplete from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Calendar } from "react-multi-date-picker";
import { usePopper } from "react-popper";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import institute_img from "../../../assets/Institute.svg";
import ArrowDown from "../../../assets/arrow.svg";
import CalendarIcon from "../../../assets/calender_black.svg";
import marker_img from "../../../assets/location_marker.svg";
import customer_name from "../../../assets/customer_name.svg";
import NeewSidebar from "../../../components/NewSideBar";
import { useLocalStorage } from "../../../hooks";
import { LoadingBackdrop } from "../../../layouts";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";
import { userState } from "../../../redux/reducers/userReducer";
import AddEditResourceFreeSlotModal from "./AddEditResourceFreeSlotModal";
import DayTimeSelector from "./DayTimeSelector";
import FreeSlotPopover from "./FreeSlotPopover";
import { getUrlForServices, getUrlForUsers } from "../../../utils/confighelpers";
import MyResourceAvailableTimeForm from "./MyResourceAvailableTimeForm";
import { handleUploadImageToS3 } from "../../../helpers";
import Dropzone from "react-dropzone";
import InitialsAvatar from "react-initials-avatar";
import add_img from "../../../assets/add_img_2.png";


const openDayAndTimeObject = [
  {
    day: "monday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "tuesday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "wednesday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "thursday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "friday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "saturday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "sunday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
];

const resource_type_list = {
  "host": "Host",
  // "employee": "Employee",
  "venue": "Venue",
  "court": "Playing field or court",
  "table": "Table",
  "stylist": "Stylist",
  "masseuse": "Masseuse",
  "beautician": "Beautician",
  "other": "Other",
};

const AddMyResource = ({ navigation }) => {

  const defaultAvatarData = "https://bmcapp.s3.eu-west-1.amazonaws.com/assets/DefaultAvatar.png";
  const location = useLocation();
  const dashboardReduxState = useSelector(dashboardState);
  const isLaptopS = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1440px)"
  );
  const [loading, setLoading] = React.useState(false);
  const [loadingDone, setLoadingDone] = React.useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [resourceDetails, setResourceDetails] = useState({
    fullName: location.state !== null && location.state?.from && location.state?.from == "registration" && location.state?.fullName ? location.state.fullName : "",
    resourceType: location.state !== null && location.state?.from && location.state?.from == "registration" ? "host" : "",
    profilePic: location.state !== null && location.state?.from && location.state?.from == "registration" ? location.state?.avatar || location.state?.profilePic || defaultAvatarData : defaultAvatarData,
    gender: location.state !== null && location.state?.from && location.state?.from == "registration" ? location.state?.gender || "" : "",
    validityStartDate: new Date(),
    validityEndDate: moment().add(30, 'days').format("YYYY-MM-DD"),
    scheduleWithoutValidity: false,
    openDayAndTime: openDayAndTimeObject
  });
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    // endDate: new Date(),
    endDate: moment().add(30, 'days').format("YYYY-MM-DD"),
  });
  const [openDayAndTime, setOpenDayAndTime] = useState(openDayAndTimeObject);
  const [busyDayAndTime, setBusyDayAndTime] = useState([
    // {
    //   slot_date: new Date(),
    //   slot_start_time: "7:00 PM",
    //   slot_end_time: "10:00 PM",
    // }
  ]);
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const [query, setQuery] = useState("");
  const userReduxState = useSelector(userState);
  const [userToken] = useLocalStorage("userToken");
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });

  const [edit, setEdit] = React.useState(false);
  const [editValues, setEditValues] = React.useState({});
  const [createFreeSlotDialog, setCreateFreeSlotDialog] = React.useState({
    isEdit: false,
    open: false,
    text: "",
    data: {},
  });
  const [formErrors, setFormErrors] = React.useState({});
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogleAutocomplete({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  });

  const onDateRangeSelect = (startDate, endDate) => {
    setResourceDetails((prevValues) => ({
      ...prevValues,
      validityStartDate: startDate,
      validityEndDate: endDate
    }));
    // setDateRange({ startDate, endDate });
    setOpenCalendar(false);
  };

  const renderDatePicker = () => {
    return (
      <>
        <div className="flex flex-col mt-7">
          <p className="text-base leading-[18px] font-normal text-black/[0.6]">
            Enter Validity period for this schedule
          </p>
          <div className="flex justify-between items-center mt-[11px] pr-[11px] pl-[27px] pt-[27px] pb-[23px] border-2 border-[#EBEBEB] hover:border-[#7E00DD] rounded-[19px]"
            onClick={() => {
              if (resourceDetails.scheduleWithoutValidity) {
                setOpenCalendar((prev) => false);
              } else {
                setOpenCalendar((prev) => !prev);
              }
            }}
          >
            <div className="flex flex-1 cursor-pointer">
              <img
                src={CalendarIcon}
                alt="calendar icon"
                className="h-[22px] w-[18px] mr-[17px]"
              />
              <div
                className={`mr-20 w-full bg-transparent text-lg leading-[22px] font-normal ${resourceDetails.scheduleWithoutValidity ? "text-[#EBEBEB]" : "text-[#212121]"}`}
              >
                {
                  resourceDetails?.validityStartDate && resourceDetails.validityEndDate
                    ? `${moment(resourceDetails?.validityStartDate).format(
                      "Do-MMM-YYYY"
                    )} to ${moment(resourceDetails?.validityEndDate).format("Do-MMM-YYYY")}`
                    : ""
                }
              </div>
            </div>
            <div className="flex justify-end">
              <img
                src={ArrowDown}
                alt="arrow down"
                className="rotate-90 h-[15px] w-2 cursor-pointer"
              />
            </div>
          </div>
        </div>
        {openCalendar && (
          <Calendar
            className="bookzy-calendar bookzy-border bookzy-range-calendar"
            range
            rangeHover
            minDate={new Date()}
            value={[resourceDetails?.validityStartDate, resourceDetails?.validityEndDate]}
            numberOfMonths={2}
            onChange={(event) => {
              const startDate = event?.[0]?.toDate();
              const endDate = event?.[1]?.toDate();
              if (startDate && endDate) {
                onDateRangeSelect(startDate, endDate);
              }
            }}
          />
        )}
      </>
    );
  };

  const onToggleSwitch = (item) => {
    let dayTimeSchedule = JSON.parse(JSON.stringify(openDayAndTime));
    dayTimeSchedule.forEach((obj) => {
      if (obj.day === item.day) {
        let isOpen = !obj.isOpen;
        obj.isOpen = isOpen;
        if (isOpen) {
          obj.openTime = [
            {
              startTime: "9:00 AM",
              endTime: "05:00 PM",
            },
          ];
        } else obj.openTime = [];
      }
    });
    setOpenDayAndTime(dayTimeSchedule);
  };

  const onTimeChange = (openTime, index) => {
    let dayTimeSchedule = JSON.parse(JSON.stringify(openDayAndTime));
    dayTimeSchedule.forEach((dayAndTimeObj, timeIndex) => {
      if (index === timeIndex) {
        dayAndTimeObj.openTime = openTime;
      }
    });
    setOpenDayAndTime(dayTimeSchedule);
  };

  const onTimeRangeCopy = (item) => {
    let dayTimeSchedule = JSON.parse(JSON.stringify(openDayAndTime));
    dayTimeSchedule.forEach((dayAndTimeObj) => {
      dayAndTimeObj.openTime = item.openTime;
    });
    setOpenDayAndTime(dayTimeSchedule);
  };

  const handleAddResource = async () => {

    const errors = {};
    // if (resourceDetails.profilePic === "") {
    //   errors.profilePic = "Please select profile photo.";
    // }
    if (resourceDetails.fullName === "") {
      errors.fullName = "Please enter resource name.";
    }
    if (resourceDetails.resourceType === "") {
      errors.resourceType = "Please select resource type.";
    }
    if (moment(resourceDetails?.validityEndDate).isBefore(moment(resourceDetails?.validityStartDate)) === true) {
      errors.validityEndDate = "Please select valid end date.";
    }
    openDayAndTime?.forEach((item) => {
      item?.openTime?.forEach((timings) => {
        if (!timings?.startTime || !timings?.endTime) {
          cogoToast.error("Please select timings");
          return;
        }
      });
    });

    setFormErrors({ ...errors });
    if (Object.keys(errors).length > 0) {
      setLoading(false);
      cogoToast.error("Please fix errors below", { hideAfter: 3 });
      return;
    }
    const payloadBody = {
      // addedByUserId: userReduxState.userData?._id,
      ...resourceDetails?._id ? { resourceId: resourceDetails?._id || "" } : {},
      fullName: resourceDetails.fullName || "",
      resourceType: resourceDetails.resourceType || "",
      profilePic: resourceDetails.profilePic || defaultAvatarData,
      scheduleWithoutValidity: resourceDetails.scheduleWithoutValidity || false,
      validityStartDate: moment(resourceDetails?.validityStartDate).format("YYYY-MM-DD"),
      validityEndDate: moment(resourceDetails?.validityEndDate).format("YYYY-MM-DD"),
      openDayAndTime: resourceDetails?.openDayAndTime,
      // busyDayAndTime: busyDayAndTime,
    };
    let config = {};
    if (edit) {
      config = {
        method: "put",
        url: `${getUrlForUsers()}updateProfile`,
        headers: {
          "x-access-token": userToken,
        },
        data: {
          ...(location.state?.from !== "list" ? { onBoardingStep: 3 } : {}),
          myResource: payloadBody
        },
      };
    } else {
      config = {
        method: "put",
        url: `${getUrlForUsers()}updateProfile`,
        headers: {
          "x-access-token": userToken,
        },
        data: {
          ...(location.state?.from !== "list" ? { onBoardingStep: 3 } : {}),
          myResource: payloadBody

        },
      };
    }

    setLoading(true)
    const request = await axios(config);
    setLoading(false)
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        // cogoToast.success(request.data.message);
        if (location.state?.from === "list") {
          navigation("/myresource/");
        } else {
          navigation("/businessprofilesetup/done");
          // navigation("/businessprofilesetup/subscription");
        }
      } else {
        cogoToast.error(request.data.message);
      }
    }
  };

  const renderLayout = (layoutType = "full", children) => {
    return layoutType == "full" ? (
      <LoadingBackdrop>
        <div>
          <div className="py-[35px] px-[46px] shadow-[0_4px_7px_-0px_rgba(0,0,0,0.02)]">
            <p className="text-[#481B3A] text-4xl leading-[44px] font-extrabold">
              Add Your Business Hours
            </p>
          </div>
          <div className="grid w-full justify-items-center min-w-[1440px]">
            <div className={`${isLaptopS ? "w-3/5" : "w-3/6"} `}>
              {children}
            </div>
          </div>
        </div>
      </LoadingBackdrop>
    ) : (
      <NeewSidebar
        navBarTitleComp={
          <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
            Add new Resource
          </h3>
        }
        children={<LoadingBackdrop loading={loading}>
          {children}
        </LoadingBackdrop>} />
    );
  }

  const getUserInfo = async (id) => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${getUrlForUsers()}getUserInfo?resourceId=${id}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await axios(config);
    if (request.data.statusCode === 200) {
      let myResource = request.data.data?.myResource || [];
      myResource.map((d) => {
        if (d._id === id) {
          setResourceDetails({
            ...d,
            profilePic: d?.profilePic ? d?.profilePic : defaultAvatarData,
          });
          // setDateRange({ startDate: request.data.data.validityStartDate, endDate: request.data.data.validityEndDate });
          setOpenDayAndTime(d?.openDayAndTime || openDayAndTimeObject)
          // setBusyDayAndTime(request.data.data?.busyDayAndTime || [])
          setEditValues((prevValues) => ({
            ...d
          }));
        }

        return d;
      });
      setLoadingDone(true)
    }
    setLoading(false);
  }

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    setResourceDetails((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };


  const handleChangePic = async (url) => {
    setResourceDetails((prevValues) => ({
      ...prevValues,
      profilePic: url,
    }));
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 16,
      fontWeight: "bold",
      border: 0,
      padding: "10px 16px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: "#464E5F",
      fontWeight: "bold",
      border: 0,
    },
    [`&.${tableCellClasses.body} > div`]: {
      // backgroundColor: "rgba(244, 244, 244, 0.4)",
      backgroundColor: "#fff",
      padding: "16px",
    },
    [`&.${tableCellClasses.body} div p`]: {
      fontSize: 11,
      color: "#B5B5C3",
      fontWeight: "500",
    },
    [`&:first-child div`]: {
      borderRadius: "12px 0 0 12px",
    },
    [`&:last-child > div`]: {
      borderRadius: "0 12px 12px 0",
      padding: "8px",
    },
    [`&:last-child > div button`]: {
      marggin: "0px",
    },
    padding: "5px 0px 5px 0px",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    margin: "0px",
    border: 0,
    [`&:hover td div`]: {
      backgroundColor: "#F4F4F4",
    },
  }));


  const formLableStyle = {
    my: 1,
    color: "#32282F99"
  };

  const formInputStyle = {
    // m: isMobile ? "0.25rem" : isTablet ? "0.5rem" : 1,
    p: 2,
    borderRadius: 3,
    border: 2,
    borderColor: "#e5e7eb",
    // backgroundColor: "rgba(0, 0, 0, 0.04)",
    // fontSize: isMobile ? 14 : isTablet ? 13 : 17,
    minWidth: 520,
    // maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  };

  const formInputClasses = {
    input: {
      border: 1,
      borderColor: "#424242",
      borderRadius: 3,

      "&::placeholder": {
        color: "#808080",
        fontWeight: 300,
      },
    },
  };
  const formSelectClasses = {
    sx: {
      "& .MuiMenu-paper": {
        border: 1,
        borderColor: "#424242",
        borderRadius: 3,
        maxHeight: 250,
        mt: 1,
      },
      "&:hover, &.Mui-focused": {
        border: 1,
        borderColor: "#7E00DD"
      },
    },
  };

  React.useEffect(() => {
    if (dashboardReduxState.resourceId !== undefined && dashboardReduxState.resourceId !== -1 && dashboardReduxState.resourceId !== "") {
      setEdit(true)
      getUserInfo(dashboardReduxState.resourceId);
    } else {
      setLoadingDone(true)
      setResourceDetails((prevValues) => ({
        ...prevValues,
        openDayAndTime: openDayAndTimeObject
      }));
      setOpenDayAndTime(openDayAndTimeObject)
      setLoadingDone(false)
    }
  }, []);
  return (renderLayout(location.state?.from === "list" ? "section" : "full",
    (<div className="">
      <div className={`flex flex-col mx-auto py-16 ${location.state.from === "list" ? "px-60" : ""}`}>
        <div className={"grid place-items-center mb-2"}>
          <Dropzone
            accept="image/png, image/jpeg"
            noDrag={true}
            onDrop={async (files) => {
              const errors = {};
              if (files[0]) {
                const file = await handleUploadImageToS3(files[0]);
                if (file.result.ok) {
                  handleChangePic(file.location);
                }
              } else {
                // handleChangePic("https://bmcapp.s3.eu-west-1.amazonaws.com/assets/DefaultAvatar.png");
                errors.photo = "Only PNG and JPG formats are allowed";
              }
              setFormErrors({ ...errors });
            }}
          >
            {({ getRootProps, getInputProps }) => {
              return (
                <div
                  {...getRootProps({ className: "dropzone" })}
                  className={""}
                >
                  <div className={"ml-4 w-[171px] h-[161px] rounded-full"}>
                    {resourceDetails.profilePic ? (<img
                      className={
                        "min-w-[151px] h-[151px] object-cover rounded-full"
                      }
                      src={resourceDetails.profilePic}
                      alt={""}
                    />) : (
                      <InitialsAvatar name={resourceDetails?.fullName} className={"initials-avatar w-[151px] h-[151px] bg-brown-coffee text-2xl"} />
                    )}
                  </div>
                  <input {...getInputProps()} />
                  <div className={"-translate-y-14 translate-x-28 w-14 h-0.5"}>
                    <Tooltip title={"Upload A New You"}>
                      <IconButton component={"span"}>
                        <img src={add_img} alt={"add icon"} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              );
            }}
          </Dropzone>
          {formErrors["profilePic"] && (
            <FormHelperText error={formErrors?.profilePic != ""}>{formErrors["profilePic"]}</FormHelperText>
          )}
        </div>
        <FormControl error={formErrors["fullName"] ? true : false} className="mt-[119px]">
          <FormLabel sx={formLableStyle}>Resource Name</FormLabel>
          <InputBase
            name={"fullName"}
            value={resourceDetails.fullName}
            onChange={handleFormValueChange}
            placeholder={"Provide the full name of the resource"}
            startAdornment={
              <InputAdornment position={"start"}>
                <img className={"w-6"} src={customer_name} alt={""} />
              </InputAdornment>
            }
            classes={formInputClasses}
            sx={formInputStyle}
            fullWidth
          />
          {formErrors["fullName"] && (
            <FormHelperText>{formErrors["fullName"]}</FormHelperText>
          )}
        </FormControl>
        <FormControl error={formErrors["resourceType"] ? true : false}>
          <FormLabel sx={formLableStyle}>Resource Type</FormLabel>
          <Select
            disableunderline="true"
            startAdornment={
              <InputAdornment position={"start"}>
                <img className={"w-6"} src={customer_name} alt={""} />
              </InputAdornment>
            }
            renderValue={(value) => (
              <div className={"flex flex-row items-center gap-3"} style={{ textTransform: 'capitalize' }}>
                {value !== "Select Resource Type" ? resource_type_list[value] : (
                  <span className={"font-light text-plain-gray"}>
                    Select Resource Type
                  </span>
                )}
              </div>
            )}
            name={"resourceType"}
            value={resourceDetails.resourceType || "Select Resource Type"}
            onChange={handleFormValueChange}
            MenuProps={formSelectClasses}
            sx={{
              ...formInputStyle,
              py: 0.5,
            }}
          >
            {Object.keys(resource_type_list).map((d, i) => (<MenuItem value={d} key={i}>{resource_type_list[d]}</MenuItem>))}
          </Select>
          {formErrors["resourceType"] && (
            <FormHelperText>{formErrors["resourceType"]}</FormHelperText>
          )}
        </FormControl>
        <FormControl error={formErrors["gender"] ? true : false}>
          <FormLabel sx={formLableStyle}>Gender</FormLabel>
          <Select
            disableunderline="true"
            startAdornment={
              <InputAdornment position={"start"}>
                <img className={"w-6"} src={customer_name} alt={""} />
              </InputAdornment>
            }
            renderValue={(value) => (
              <div className={"flex flex-row items-center gap-3"}>
                {value !== "Select Gender" ? value : (
                  <span className={"font-light text-plain-gray"}>
                    Select Gender
                  </span>
                )}
              </div>
            )}
            name={"gender"}
            value={resourceDetails.gender || "Select Gender"}
            onChange={handleFormValueChange}
            MenuProps={formSelectClasses}
            sx={{
              ...formInputStyle,
              py: 0.5,
            }}
          >
            <MenuItem value={"Male"}>Male</MenuItem>
            <MenuItem value={"Female"}>Female</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </Select>
          {formErrors["gender"] && (
            <FormHelperText>{formErrors["gender"]}</FormHelperText>
          )}
        </FormControl>
        <MyResourceAvailableTimeForm
          profileDetails={resourceDetails}
          setProfileDetails={setResourceDetails}
          reloadData={loadingDone}
          formErrors={formErrors}
        />
        {false && (<div className="flex flex-col gap-3 mt-8" style={{ display: "none" }}>
          <div className="flex items-center justify-between gap-3">
            <p className="text-base leading-[18px] font-normal text-black/[0.6]">
              Free or unavailable slot
            </p>
            <Button
              sx={{
                color: "common.white",
                backgroundColor: "secondary.main",
                boxShadow: "none",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "secondary.main",
                  boxShadow: "none",
                },
                fontWeight: 600,
                borderRadius: 3,
                fontSize: 14,
              }}
              variant={"contained"}
              onClick={() => {
                setCreateFreeSlotDialog((prevValues) => ({
                  ...prevValues,
                  isEdit: false,
                  data: {
                    // slot_date: moment().format("YYYY-DD-MM"),
                    slot_date: moment(),
                    slot_start_time: moment("9:00 AM", "h:mm A").format("h:mm A"),
                    slot_end_time: moment("10:00 AM", "h:mm A").format("h:mm A"),
                  },
                  text: "",
                  open: true,
                }));
              }}
            >
              Add New Free Slot
            </Button>
          </div>
          <TableContainer className="border-2 border-[#EBEBEB] rounded-[10px]">
            <Table sx={{ widht: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Date</StyledTableCell>
                  <StyledTableCell align="center">Start time</StyledTableCell>
                  <StyledTableCell align="center">End time</StyledTableCell>
                  <StyledTableCell width="10%" align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {busyDayAndTime.length ? busyDayAndTime.map((row, i) => (
                  <StyledTableRow key={i} >
                    <StyledTableCell align="center">
                      <Box>
                        {row.slot_date ? moment(row.slot_date, 'YYYY-MM-DD').format('DD-MMM-YYYY') : ""}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box>
                        {row.slot_date ? moment(moment(row.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + row.slot_start_time, 'YYYY-MM-DD h:mm A').format('h:mm A') : ""}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box>
                        {row.slot_date ? moment(moment(row.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + row.slot_end_time, 'YYYY-MM-DD h:mm A').format('h:mm A') : ""}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Box>
                        <FreeSlotPopover
                          key={i}
                          slotDetails={{ resourceId: editValues?._id || "", ...row }}
                          handleEditSlot={(data) => {
                            setCreateFreeSlotDialog((prevValues) => ({
                              ...prevValues,
                              isEdit: i + 1,
                              data: {
                                // slot_date: moment().format("YYYY-DD-MM"),
                                slot_date: moment(data.slot_date, 'YYYY-MM-DD'),
                                slot_start_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_start_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
                                slot_end_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_end_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
                              },
                              text: "",
                              open: true,
                            }));
                          }}
                          handleDeleteSlot={(data) => {
                            setBusyDayAndTime(busyDayAndTime.filter((bdata, bi) => bi !== i))
                          }}
                          loading={loading}
                          setLoading={setLoading}
                        />
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                )) : (
                  <StyledTableRow key={0} >
                    <StyledTableCell align="center" colSpan={4}>
                      <Box className="rounded-2xl" sx={{ borderRadius: "12px !important" }}>
                        <p>There is no busy slots</p>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <AddEditResourceFreeSlotModal
            isEdit={createFreeSlotDialog.isEdit}
            loading={loading}
            text={createFreeSlotDialog.text || ""}
            open={createFreeSlotDialog.open}
            data={createFreeSlotDialog.data}
            onClose={() => {
              setCreateFreeSlotDialog((prevValues) => ({
                ...prevValues,
                open: false,
              }));
            }}
            onSubmit={async (data) => {
              // deletePaymentProfile()

              // if (edit) {
              //   if (editValues?._id !== "" && await checkTimeValid(data)) {
              //     setCreateFreeSlotDialog((prevValues) => ({
              //       ...prevValues,
              //       text: "You are not allowed to modify free slot, you have bookings available at this time",
              //     }));
              //     return true;
              //   }
              // }

              // if (createFreeSlotDialog.isEdit) {
              //   setBusyDayAndTime(busyDayAndTime.map((bdata, i) => {
              //     if (i === (createFreeSlotDialog.isEdit - 1)) {
              //       return {
              //         slot_date: moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              //         slot_start_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_start_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
              //         slot_end_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_end_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
              //       }
              //     }
              //     return bdata;
              //   }))
              // } else {
              //   setBusyDayAndTime([...busyDayAndTime, {
              //     slot_date: moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              //     slot_start_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_start_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
              //     slot_end_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_end_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
              //   }])
              // }

              setCreateFreeSlotDialog((prevValues) => ({
                ...prevValues,
                open: false,
              }));
            }}
          />
        </div>)}
      </div>
      <div className={`flex items-center ${location.state?.from === "list" ? "justify-center" : "justify-center"} px-60 pb-[55px] `}>
        {(location.state?.from === "list") ? (
          <button
            className="border border-[#F64E60] py-3 px-9 rounded-lg"
            onClick={() => {
              navigation("/myresource/");
            }}
          >
            <p className="text-[#F64E60] text-base font-semibold">Back</p>
          </button>) : ""}
        <button
          className="bg-[#481B3A] rounded-2xl py-4 px-[72px] ml-5"
          onClick={() => {
            handleAddResource();
          }}
        >
          <p className="text-base leading-[19px] text-white font-semibold">
            {edit ? "Update" : "Continue"}
          </p>
        </button>
      </div>
    </div>
    ))
  );
};

export default AddMyResource;
