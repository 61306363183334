import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import axios from "axios";

import { Box, Popover, Rating } from "@mui/material";
import { useLocalStorage } from "../../../hooks";
import location_tile from "../../../assets/location_tile.svg";
import { getUrlForBookings, getUrlForServices, getUrlForUsers } from "../../../utils/confighelpers";
import InitialsAvatar from "react-initials-avatar";
import DeleteDialog from "../../../components/DeleteDialog";
import { useSelector } from "react-redux";
import { userState } from "../../../redux/reducers/userReducer";
import ErrorDialog from "../../confirmationModals/ErrorDialog";


const resource_type_list = {
  "host": "Host",
  // "employee": "Employee",
  "venue": "Venue",
  "court": "Playing field or court",
  "table": "Table",
  "stylist": "Stylist",
  "masseuse": "Masseuse",
  "beautician": "Beautician",
  "other": "Other",
};

export default function MyResourceTile({
  popover,
  setLoading,
  handleSessionClick,
  reload,
  item,
  allResources,
}) {

  const userReduxState = useSelector(userState);
  const [eventStatusColors, setEventStatusColors] = React.useState({
    "draft": { //draft
      borderWidth: 0,
      borderColor: "#D7BB294D",
      color: "#8C780C",
      backgroundColor: "#D7BB294D"
    },
    "false": { //draft
      borderWidth: 0,
      borderColor: "#D7BB294D",
      color: "#8C780C",
      backgroundColor: "#D7BB294D"
    },
    "published": { //published
      borderWidth: 0,
      borderColor: "#468C111F",
      color: "#468C11",
      backgroundColor: "#468C111F" //#468C111F
    },
    "true": { //published
      borderWidth: 0,
      borderColor: "#468C111F",
      color: "#468C11",
      backgroundColor: "#468C111F" //#468C111F
    },
    "cancelled": {
      borderWidth: 0,
      borderColor: "#ff404033",
      color: "#FF4040",
      backgroundColor: "#ff404033" // #ff404033
    },
    "refunded": {
      borderWidth: 0,
      borderColor: "#ff404033",
      color: "#FF4040",
      backgroundColor: "#ff404033"
    }
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userToken] = useLocalStorage("userToken");
  const [delDialogOpen, setDelDialogOpen] = React.useState(false);
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
  const [errorText, setErrorText] = React.useState(false);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const deleteResource = async (item) => {
    setDelDialogOpen(false)
    setLoading(true)
    const manageServiceBookingsConfig = {
      method: "get",
      url: `${getUrlForBookings()}getServiceBooking?teacherId=${userReduxState.userData?._id}&resourceId=${item._id}&page=${1}&limit=5&filterBy=confirmed`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const manageServiceBookingsRequest = await axios(manageServiceBookingsConfig);
    if (manageServiceBookingsRequest.status === 200) {
      if (manageServiceBookingsRequest.data.data.data.length) {
        //error
        setErrorText("Cannot delete a resource with active appointments in future. Please ensure all upcoming appointments for this resource is cancelled before deleting it.");
        setOpenErrorDialog(true);
      } else {
        // delete
        const manageClassesConfig = {
          method: "put",
          url: `${getUrlForUsers()}updateProfile`,
          headers: {
            "x-access-token": userToken,
          },
          data: {
            myResource: {
              ...item,
              action: "delete"
            }
          },
        };
        const responce = await axios(manageClassesConfig);
        if (responce.status === 200) {
        }

        reload();
      }
    } else {
    }
    // reload();
    setLoading(false)
  };

  const popoverMenuOpen = Boolean(anchorEl);
  const popoverId = popoverMenuOpen ? "simple-popover" : undefined;

  return (
    <div
      className={`my-3 p-3 flex flex-row items-center gap-4 cursor-pointer 
        } bg-white hover:bg-[#e1e1e1] shadow-md border border-bright-gray rounded-2xl`}
    >
      <div className="flex flex-row items-top gap-4 grow" onClick={() => { handleSessionClick(); }}>
        <div
          className=""
        >
          {item.profilePic ? (<img
            className={
              "w-[50px] max-w-[50px] h-[50px] rounded-full"
            }
            src={item.profilePic}
            alt={""}
          />) : (
            <InitialsAvatar name={item?.fullName} className={"initials-avatar w-[50px] h-[50px] bg-brown-coffee text-2xl"} />
          )}
        </div>
        <div className={`flex item-top grow`}>
          <div className={`flex flex-col item-center w-full gap-1`}>
            <h3
              className={`text-brown-coffee font-bold text-base capitalize`}
            >
              {item?.fullName}
            </h3>
            <div className="grid grid-cols-2 items-top">
              <div className={`flex flex-col item-center`}>
                <div className={"flex flex-row items-center gap-2"}>

                  <p
                    className={`text-brown-coffee text-xs capitalize`}
                  >
                    {resource_type_list[item.resourceType]}
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {popover && (
        <>
          <div className={"flex flex-col items-center ml-auto"}>
            <div
              className={
                "grid place-items-center rounded-xl cursor-pointer"
              }
              onClick={handlePopoverOpen}
            >
              <MoreVertIcon
                color={anchorEl ? "secondary" : "primary"}
              />
            </div>

            <Popover
              id={popoverId}
              open={popoverMenuOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                my: 1,
                "& .MuiPopover-paper": {
                  boxShadow: "none",
                  border: 1,
                  borderColor: "#EBEBEB",
                  borderRadius: 3,
                },
              }}
            >
              <div className={"flex flex-col gap-3 py-5"}>
                <button
                  onClick={() => {
                    handlePopoverClose();
                    handleSessionClick();
                    // handleDateTimeModalOpen();
                  }}
                >
                  <p
                    className={`${"text-light-brown-coffee"
                      } text-sm text-left`}
                  >
                    Edit
                  </p>
                </button>
                {allResources.length > 1 && <button
                  onClick={() => {
                    handlePopoverClose();
                    // handleSessionClick();
                    setDelDialogOpen(true)
                    // deleteResource(item);
                  }}
                >
                  <p
                    className={`${"text-light-brown-coffee"
                      } text-sm text-left`}
                  >
                    Delete
                  </p>
                </button>}
              </div>
            </Popover>
          </div>
        </>
      )}

      <DeleteDialog
        open={delDialogOpen}
        title={"WARNING"}
        // message={"Are you sure you want to cancel this booking?"}
        message={"Are you sure you want to delete this resource?"}
        type={""}
        handleClose={() => setDelDialogOpen(false)}
        handleDelete={() => { deleteResource(item); }}
      />
      
      <ErrorDialog
        open={openErrorDialog}
        text={errorText}
        onClose={() => setOpenErrorDialog(false)}
      />
    </div>
  );
}
