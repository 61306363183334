import { Box } from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import moment from "moment";
import React from "react";

import { useLocalStorage } from "../../../../hooks";

import { useLocation } from "react-router-dom";
import charetleft from "../../../../assets/charetleft.svg";
import dropdownbottom from "../../../../assets/dropdownbottom.svg";
import NeewSidebar from "../../../../components/NewSideBar";
import {
    LoadingBackdrop
} from "../../../../layouts";
import ActionPopover from "./ActionPopover";
import DeleteDialog from "../../../../components/DeleteDialog";
import { getUrlForBookings } from "../../../../utils/confighelpers";
import { displayAvailablePaymentMethods } from "../../../../utils/constants";


export default function BookingDetails({ navigation }) {
    const location = useLocation();
    const [userToken] = useLocalStorage("userToken");
    const [bookingInfo, setBookingInfo] = React.useState();
    const [loading, setLoading] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [delDialogOpen, setDelDialogOpen] = React.useState(false);

    const slotsDisplay = {
        "15": "15 minutes",
        "30": "30 minutes",
        "45": "45 minutes",
        "60": "60 minutes",
        "75": "1 hours 15 minutes",
        "90": "1 hours 30 minutes",
        "105": "1 hours 45 minutes",
        "120": "2 hours",
        "135": "2 hours 15 minutes",
        "150": "2 hours 30 minutes",
        "165": "2 hours 45 minutes",
        "180": "3 hours",
        "195": "3 hours 15 minutes",
        "210": "3 hours 30 minutes",
        "225": "3 hours 45 minutes",
        "240": "4 hours",
        "255": "4 hours 15 minutes",
        "270": "4 hours 30 minutes",
        "285": "4 hours 45 minutes",
        "300": "5 hours",
        "315": "5 hours 15 minutes",
        "330": "5 hours 30 minutes",
        "345": "5 hours 45 minutes",
        "360": "6 hours",
        "375": "6 hours 15 minutes",
        "390": "6 hours 30 minutes",
        "405": "6 hours 45 minutes",
        "420": "7 hours",
        "435": "7 hours 15 minutes",
        "450": "7 hours 30 minutes",
        "465": "7 hours 45 minutes",
        "480": "8 hours",
    }
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const fetchBookingsDetails = async () => {
        console.log("location.state.bookingId", location.state.bookingId);
        setLoading(true)
        const config = {
            method: "get",
            url: `${getUrlForBookings()}getServiceBooking?bookingId=${location.state.bookingId}`,
            headers: {
                "x-access-token": userToken,
            },
        };
        const request = await axios(config);
        if (request.status === 200) {
            if (request.data.statusCode === 200) {
                setBookingInfo(request.data.data)
            }
        }
        setLoading(false)
    }

    React.useEffect(() => {
        fetchBookingsDetails()
    }, [])

    const getStudentInfo = () => {
        let studentDetails = {}

        // if (bookingInfo && bookingInfo?.schedule_class_detail?.participants?.length) {
        //     studentDetails = bookingInfo?.schedule_class_detail?.participants.find((item) => item?.bookingId === bookingInfo?.bookingId)
        // }

        if (bookingInfo) {
            studentDetails = bookingInfo?.student_detail
        }

        return studentDetails;
    }

    const handleDelete = async () => {
        setDelDialogOpen(false)
        if (bookingInfo) {
            setLoading(true);
            cogoToast.loading("Loading...");
            const body = {
                bookingId: bookingInfo?.bookingId,
                service_id: bookingInfo?.service_id,
                participantId: bookingInfo?.studentId,
                // isRefundApplicable: true,
                forceCancellation: true,
            };
            const config = {
                method: "delete",
                url: `${getUrlForBookings()}cancelServiceBooking`,
                headers: {
                    "x-access-token": userToken,
                },
                data: body,
            };
            const request = await axios(config);
            setLoading(false);
            if (request.status === 200) {
                if (request.data.statusCode === 200) {
                    console.log(request);
                    cogoToast.success("Removed Participant");
                    await fetchBookingsDetails();
                } else {
                    console.log(request);
                    cogoToast.error(request.data.message);
                }
            }
        }
    };

    const getPaymentStatus = () => {
        let paymentStatus = bookingInfo?.paymentStatus;
        // if ((bookingInfo?.paymentStatus == "Paid" || bookingInfo?.paymentStatus == "Free") && bookingInfo.paymentMethod == "NA" && parseInt(bookingInfo.amount) <= 0) {
        //     paymentStatus = 'Free';
        // }
        var displayContent = (<button
            className={
                "h-[30px] bg-[#5B274B] rounded-lg ml-4"
            }
            onClick={() => null}
            style={{ padding: '0px 10px' }}>
            <p className={"font-bold text-sm text-white text-center"}>
                {paymentStatus}

            </p>
        </button>)
        if (bookingInfo?.status == "Cancelled" && (bookingInfo?.paymentStatus == "Unpaid" || bookingInfo?.paymentStatus == "Not-refunded") && bookingInfo.paymentMode != "Online") {
            displayContent = (<span>{displayContent}<br></br> <span className="ml-4" style={{ color: '#00000099' }}>Customer never paid for it</span></span>);
        } else if (bookingInfo?.status == "Cancelled" && bookingInfo?.paymentStatus == "Not-refunded" && bookingInfo.paymentMode == "NA" && parseInt(bookingInfo.amount) <= 0) {
            displayContent = (<span>{displayContent}<br></br> <span className="ml-4" style={{ color: '#00000099' }}>It was a free service</span></span>);
        }

        return displayContent
    };

    const getPaymentMethod = () => {
        if (bookingInfo?.paymentMethod && bookingInfo?.paymentMethod !== 'NA') {
            return Object.keys(displayAvailablePaymentMethods).indexOf(bookingInfo?.paymentMethod) >= 0 ? displayAvailablePaymentMethods[bookingInfo?.paymentMethod] : "UNKNOWN";
        } /* else if (bookingInfo?.paymentStatus == "Unpaid" && bookingInfo.paymentMethod == "NA" && bookingInfo.paymentMode != "Online" && parseInt(bookingInfo.amount) > 0) {
            return 'pay at venue';
        } else if ((bookingInfo?.paymentStatus == "Paid" || bookingInfo?.paymentStatus == "Free") && bookingInfo.paymentMethod == "NA" && parseInt(bookingInfo.amount) <= 0) {
            //return 'Free';
            return 'Not Applicable';
        } */
        return 'UNKNOWN';
    };


    const calculateBoughtTickets = () => {
        let totalBookedTickets = 0;

        if (bookingInfo && bookingInfo?.ticketsBought?.length) {
            bookingInfo?.ticketsBought.forEach((item) => {
                totalBookedTickets = totalBookedTickets + parseInt(item.bookingQty)
            })
        }

        return totalBookedTickets
    }

    const calculateTotalPrice = () => {
        if (bookingInfo?.amount) {
            return parseFloat(bookingInfo?.amount || 0).toFixed(2)
        } else {
            return bookingInfo?.servicesBought ? parseFloat(bookingInfo?.servicesBought.totalPrice).toFixed(2) : "0"
        }
    }

    const calculateUsersReceiveTotalPrice = () => {
        return bookingInfo?.servicesBought ? parseFloat(bookingInfo?.servicesBought.amountToUser || 0).toFixed(2) : "0"
    }

    const calculateServiceFees = () => {
        return bookingInfo?.servicesBought ? parseFloat(bookingInfo?.servicesBought.serviceFee || 0).toFixed(2) : "0"
    }

    const calculatePaymentServiceFees = () => {

        if (bookingInfo?.totalPaymentServiceFee) {
            return parseFloat(bookingInfo?.totalPaymentServiceFee).toFixed(2)
        }
        return parseFloat(0).toFixed(2)
    }

    const getResourceName = () => {
        let resourceDetail = { fullName: "" };
        if (bookingInfo?.resourceId && bookingInfo?.teacher_detail && bookingInfo?.teacher_detail?.myResource && bookingInfo?.teacher_detail?.myResource.length > 0) {
            bookingInfo?.teacher_detail?.myResource.map((d) => {
                if (d._id.toString() == bookingInfo?.resourceId) {
                    resourceDetail = d;
                }
            });
        }
        return resourceDetail?.fullName || "";
    }

    // console.log("bookingInfo", bookingInfo)

    return (
        <NeewSidebar
            navBarTitleComp={
                <span style={{ display: 'flex', alignItems: 'center' }} onClick={() => navigation(-1)}>
                    <img src={charetleft} className="inline align-baseline mr-3 cursor-pointer" />
                    <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
                        Booking Details
                    </h3>
                </span>
            }
            children={<LoadingBackdrop loading={loading}>
                <Box
                    component='main'
                    sx={{
                        flexGrow: 1,
                        py: 2,
                        px: 3,
                        overflowX: "hidden",
                        minHeight: "100vh",
                    }}
                    className="grid"
                >
                    <Box className="flex flex-col w-full">
                        <Box className="flex justify-end p-3">
                            <Box className="flex-none">
                                <button
                                    className={"bg-[#3699FF] text-white rounded w-[160px] p-3"}
                                    onClick={handlePopoverOpen}
                                >
                                    <span className={"flex flex-row items-center"}>
                                        <p className={"w-full"}>Action</p>
                                        <img src={dropdownbottom} alt={""} />
                                    </span>
                                </button>
                            </Box>
                            <Box className="pl-2">
                                <button
                                    disabled={bookingInfo?.status == "Cancelled" ? true : moment(bookingInfo?.slot_date + " " + bookingInfo?.slot_start_time).subtract({ hours: 0 }).isBefore()}
                                    className={"bg-brown-coffee text-white rounded w-[210px] p-3 text-center cursor-pointer"}
                                    style={(bookingInfo?.status == "Cancelled" || moment(bookingInfo?.slot_date + " " + bookingInfo?.slot_start_time).subtract({ hours: 0 }).isBefore()) ? { backgroundColor: '#D3D3D3' } : null}
                                    // onClick={handleDelete}
                                    onClick={() => setDelDialogOpen(true)}
                                >
                                    Cancel Booking
                                </button>
                            </Box>
                        </Box>
                        <Box style={{ padding: '10px 50px' }}>
                            <Box className="flex mb-10">
                                <p style={{ fontSize: '28px', lineHeight: '24px', fontWeight: 'bold' }}>Booking ID</p>
                                <p style={{ fontSize: '28px', lineHeight: '24px', fontWeight: 'bold', color: '#00000099' }}>: {bookingInfo?.bookingId}</p>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px', color: 'black' }}>Booking Status</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px', ...((bookingInfo?.status && bookingInfo?.status === "Cancelled") ? { color: '#FF4040' } : {}) }}>{bookingInfo?.status}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px', color: 'black' }}>Booking Date</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{moment(bookingInfo?.bookingDate).format('DD-MM-YYYY')}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px', color: 'black' }}>Cancellation Date</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{bookingInfo?.status?.toLowerCase() === "cancelled" ? moment(bookingInfo?.cancellationDate).format('DD-MM-YYYY') : 'Not Applicable'}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px', color: 'black' }}>Payment Status</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'left' }}>
                                    <span>:</span>
                                    {getPaymentStatus(bookingInfo)}
                                    {/* <button
                                        className={
                                            "h-[30px] bg-[#5B274B] rounded-lg ml-4"
                                        }
                                        onClick={() => null}
                                        style={{ padding: '0px 10px' }}>
                                        <p className={"font-bold text-sm text-white text-center"}>
                                            {bookingInfo?.paymentStatus}

                                        </p>
                                    </button> */}
                                </span>
                            </Box>
                        </Box>
                        <Box style={{ padding: '10px 50px' }}>
                            <Box className="flex mb-10">
                                <p style={{ fontSize: '28px', lineHeight: '24px', fontWeight: 'bold' }}>Customer Details</p>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Full Name</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{getStudentInfo()?.fullName ? getStudentInfo()?.fullName : 'UNKNOWN'}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Email</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px', color: '#7E00DD' }}>{getStudentInfo()?.email ? getStudentInfo()?.email : 'UNKNOWN'}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Mobile Number</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{getStudentInfo()?.username ? "" + getStudentInfo()?.username : 'UNKNOWN'}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>City</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{bookingInfo?.venue_detail?.address?.city}</span>
                                </span>
                            </Box>
                        </Box>
                        <Box style={{ padding: '10px 50px' }}>
                            <Box className="flex mb-10">
                                <p style={{ fontSize: '28px', lineHeight: '24px', fontWeight: 'bold' }}>Purchase Details</p>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Activity Booked</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{bookingInfo?.service_detail?.serviceName ? bookingInfo?.service_detail?.serviceName : ""}</span>
                                </span>
                            </Box>
                            {/* <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Activity Status</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px', ...((bookingInfo?.service_detail && bookingInfo?.service_detail?.serviceStatus === "Cancelled") ? { color: '#FF4040' } : {}) }}>{bookingInfo?.service_detail?.serviceStatus ? (bookingInfo?.service_detail?.serviceStatus === "Cancelled" ? "This event is cancelled" : bookingInfo?.service_detail?.serviceStatus) : 'UNKNOWN'}</span>
                                </span>
                            </Box> */}
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Activity Date</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px', color: '#7E00DD' }}>{moment(bookingInfo?.slot_date + " " + bookingInfo?.slot_start_time, 'YYYY-MM-DD h:mm A').format('DD-MMM-YYYY, h:mm A')}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Duration</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{bookingInfo?.duration ? slotsDisplay[bookingInfo?.duration] : ""}</span>
                                </span>
                            </Box>
                            {bookingInfo?.resourceId && (<Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Resource Name</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{getResourceName()}</span>
                                </span>
                            </Box>)}
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Price</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>€{bookingInfo?.servicesBought ? parseFloat(bookingInfo?.servicesBought.price).toFixed(2) : "0"}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Booking fee</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>€{calculateServiceFees()}, <span style={{ color: '#00000099' }}>non refundable</span></span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Payment Service Fee</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>€{calculatePaymentServiceFees()}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <Box className="flex px-3 py-1 rounded bg-[#D9D9D9]">
                                    <p style={{ fontSize: '16px', lineHeight: '24px', width: '188px' }}>Total Amount Paid</p>
                                    <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                        <span>:</span>
                                        <span style={{ marginLeft: '20px' }}>€{calculateTotalPrice()}, <span style={{ color: '#00000099' }}>Including VAT</span></span>
                                    </span>
                                </Box>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>What you get</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>€{calculateUsersReceiveTotalPrice()} </span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Payment Method</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}> {getPaymentMethod()}</span>
                                </span>
                            </Box>
                        </Box>

                    </Box>
                    {bookingInfo ? <ActionPopover
                        bookingDetails={bookingInfo}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        handleUpdate={fetchBookingsDetails}
                        setLoading={setLoading}
                    /> : null}

                    <DeleteDialog
                        open={delDialogOpen}
                        title={"WARNING"}
                        // message={"Are you sure you want to cancel this booking?"}
                        message={"Cancellation will lead to full refund to the customer. Are you sure you want to cancel this booking?"}
                        type={""}
                        handleClose={() => setDelDialogOpen(false)}
                        handleDelete={() => handleDelete()}
                    />
                </Box>
            </LoadingBackdrop>} />
    );
}
